import React, { useState, useEffect } from "react";
import axios from 'axios';
import { NavLink } from 'react-router-dom'
// import Cookies from 'js-cookie';

const base_url = "https://admin.brookfieldproperties.app";

function Narratives() {
    const [narratives, setNarratives] = useState([]);

    function fetchNarratives() {
      try {
          const get_url = base_url + '/api/cms/narrative/';
          axios({
              method: 'get',
              url: get_url,
              // headers: {
              //   Authorization: 'Token ' + Cookies.get('ud_token')
              // },
          })
          .then(response => {
              const assetJSON = response.data;
              setNarratives(assetJSON);
  
          });
      } catch (error) {
          console.error(error);
      }
    };
    
  useEffect(() => {        
      fetchNarratives()
  }, []);

  useEffect(() => {
    console.log('narratives', narratives);
  }, [narratives]);

  function handleAddNarrative(e) {
    const api_url = base_url + '/api/cms/narrative-add/'
    var dataJSON = {}
    axios({
        method: 'POST',
        url: api_url,
        data: dataJSON,
        // headers: {
        //     Authorization: 'Token ' + Cookies.get('ud_token')
        // },
    })
    .then(response => {
        fetchNarratives();

    })
    .catch(error => {
        console.log('error', error.response);
    });



  }



  return (

      <section className="listPage" id="narrativesPage">
      <div className="addNarrative" onClick={() => handleAddNarrative()}>Add Narrative +</div>
      {(narratives.map((narrative, i) =>
            <NavLink 
              key={"narrative_" + narrative.id}
              to={'/narratives/' + narrative.id}
              // onClick={() => handlePageChange(route.path)}
              activeClassName="on"
              className="narrative row"
            >
                <p>{narrative.title}</p>
            </NavLink>
   
        ))}

        
      </section>

   
  )

}
export default Narratives;


