import React, { useState, useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import SwiperCore, { Navigation, A11y, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../static/svg/loader.svg";
import Close from "../static/svg/close.svg";
import CloseDelete from "../static/svg/close-delete.svg";
import LeftArrow from "../static/svg/leftarrow.svg";
import Lock from "../static/svg/lock.svg";
import Unlock from "../static/svg/unlock.svg";
import OpenLink from "../static/svg/openLink-w.svg";

import {
  useParams
} from "react-router-dom";
import axios from "axios";

import imagePlaceholder from '../static/img/image.png'

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

const AnimationProperties = [
  {'name': 'opacity', 'slug': 'opacity'},
  {'name': 'x pos', 'slug': 'translateX'},
  {'name': 'y pos', 'slug': 'translateY'},
  {'name': 'scale', 'slug': 'scale'}
]

const EasingProperties = [
  {'name': 'default', 'slug': 'default'},
  {'name': 'linear', 'slug': 'linear'},
  {'name': 'easeInOutQuad', 'slug': 'easeInOutQuad'},
  {'name': 'easeInOutQuad2', 'slug': 'easeInOutQuad2'},
  {'name': 'elastic', 'slug': 'elastic'}
]

function stripImg(string) {
  var x = string.replace('img/', '');
  // var y = x.replace('.png', "");
  return x;
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


var LIVE_HOST = false;
var HOST_NAME = window.location.hostname;


if ((HOST_NAME === 'cms.brookfieldproperties.app')||(LIVE_HOST === true)) {
  var preview_url = 'https://www.brookfieldproperties.app'
  var base_url = "https://admin.brookfieldproperties.app/api/cms/";
  var base_admin_url = "https://admin.brookfieldproperties.app"
} else {
  var preview_url = 'https://staging.brookfieldproperties.app'
  var base_url = "https://admin.staging.origin.brookfieldproperties.app/api/cms/";
  var base_admin_url = "https://admin.staging.origin.brookfieldproperties.app"
}

console.log('hostname, base_admin_url', HOST_NAME, base_admin_url);

// var preview_url = 'https://www.brookfieldproperties.app'
// var base_url = "https://admin.brookfieldproperties.app/api/cms/";
// var base_admin_url = "https://admin.brookfieldproperties.app"

function Home() {

    let { id } = useParams();
    const nodeRef = React.useRef(null);
    const [displays, setDisplays] = useState(1);
    const [showFullCanvas, setShowFullCanvas] = useState(true);
    const [narrative, setNarrative] = useState(null);
    const [sections, setSections] = useState([]);
    const [sectionChanges, setSectionChanges] = useState([]);
    const [sectionId, setSectionId] = useState(null);
    const [activeSectionObj, setActiveSectionObj] = useState(null);
    const [section, setSection] = useState(null);
    const [components, setComponents] = useState([]);
    const [componentDimensions, setComponentDimensions] = useState([]);
    const [aspectRatio, setAspectRatios] = useState(1);
    const [narrativeActive, setNarrativeActive] = useState(true);
    const [narrativeTitle, setNarrativeTitle] = useState(null);
    const [unSavedChanges, setUnSavedChanges] = useState(false);
    const [partnerChanges, setPartnerChanges] = useState([]);
    const [swiperInst, setSwiperInst] = useState(null);
    const [newMedia, setNewMedia] = useState([]);
    const [mediaChoice, setMediaChoice] = useState(null);
    const [acceptValues, setAcceptValues] = useState('init');
    const [showMediaDropDown, setShowMediaDropDown] = useState(true);
    const [showAnimDropDown, setShowAnimDropDown] = useState(false);
    const [newComponentID, setNewComponentID] = useState(null);
    const [newComponentPopUpID, setNewComponentPopUpID] = useState(null);
    const [newComponentDimension, setNewComponentDimension] = useState(null);
    const [activeSettingId, setActiveSettingId] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [showManifest, setShowManifest] = useState(false);
    const [menuOpen, setMenuOpen] = useState(true);
    const [showError, setShowError] = useState(false);
    const [triggerSaveForm, setTriggerSaveForm] = useState(false);
    const [newAnimObj, setNewAnimObj] = useState({});
    const [showNewAnimObjReady, setShowNewAnimObjReady] = useState(false);
    const [showNewAnimObj, setShowNewAnimObj] = useState(false);
    const [newStateAnimObj, setNewStateAnimObj] = useState({});
    const [showNewStateAnimObj, setShowNewStateAnimObj] = useState(false);
    const [showGenerateScreenshot, setShowGenerateScreenshot] = useState(true);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [animationPresets, setAnimationPresets] = useState([]);
    const [showAnimPresetDropDown, setShowAnimPresetDropDown] = useState(false);
    const [animPropOn, setAnimPropOn] = useState(null);
    // const animPropRef = useRef();

    const scaleChoices = ['0%', '50%', '100%', '120%', '300%'];
    const opacityChoices = ['0%', '25%', '50%', '75%', '100%'];
    const xPosChoices = ['-1920px', '-960px', '-100px', '-50px', '-25px', '0px', '25px', '50px', '100px', '960px', '1920px'];
    const yPosChoices = ['-1080px', '-540px', '-100px', '-50px', '-25px', '0px', '25px', '50px', '100px', '540px', '1080px'];
    const startParallaxChoices = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3];
    const endParallaxChoices = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7];
    const durationChoices = ['0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0'];
    const delayChoices = ['0.00', '0.25', '0.50', '0.75', '1.0', '1.25', '1.50', '1.75', '2.0', '2.25', '2.5', '2.75', '3.0'];
   
    // const [pageState, setPageState] = useState(null);

    function handleNewComponent() {
      var dimensionIndex = componentDimensions.findIndex(item => item.id === newComponentDimension['id']);
      showSettings(newComponentDimension['id'], dimensionIndex, false);
      setNewComponentDimension(null);
      if (newComponentDimension['is_background'] === true) {
        handlePostSave();
      }
    }


    function fetchJSON(hasNewObjId) {
        try {
            const get_url = base_url + "narrative/" + id;
            axios({
                method: "get",
                url: get_url,
                // headers: {
                //   Authorization: 'Token ' + Cookies.get('ud_token')
                // },
            }).then((response) => {
                const assetJSON = response.data;
                setNarrative(assetJSON);
                setNarrativeActive(assetJSON.active);
                setNarrativeTitle(assetJSON.title);
                setSections(assetJSON.sections);
                setShowLoader(false);
                setShowFullCanvas(false);
                if (swiperInst) {
                  swiperInst.update();  
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    function fetchPresets(narrativeId) {
      try {
          const get_url = base_url + "animation-preset/" + narrativeId;
          axios({
              method: "get",
              url: get_url,
              // headers: {
              //   Authorization: 'Token ' + Cookies.get('ud_token')
              // },
          }).then((response) => {
              const assetJSON = response.data;
              setAnimationPresets(assetJSON);
             
          });
      } catch (error) {
          console.error(error);
      }
    }

    function fetchSection(objID) {
      try {
          const get_url = base_url + "section/" + objID;
          axios({
              method: "get",
              url: get_url,
              // headers: {
              //   Authorization: 'Token ' + Cookies.get('ud_token')
              // },
          }).then((response) => {
              const assetJSON = response.data;
              var sectionsCopy = [ ...sections ];
              var sectionIndex = sectionsCopy.findIndex(item => item.id === objID);
              sectionsCopy[sectionIndex] = assetJSON;
              setSections(sectionsCopy);
             
          });
      } catch (error) {
          console.error(error);
      }
    }

    useEffect(() => {
      fetchJSON(false);
      fetchPresets(id)
      // login();
    }, []);

    useEffect(() => {
      console.log('animationPresets', animationPresets)
      // login();
    }, [animationPresets]);
   

    function handleSwiperInit(swiper) {
      setSwiperInst(swiper);
      if (sections.length > 0) {
        setSectionId(sections[0].id);
      }
    }


    useEffect(() => {
      // console.log('sectionChanges Obj', sectionChanges);
    }, [sectionChanges]);

    useEffect(() => {
      // console.log('partnerChanges OBJ', partnerChanges);
    }, [partnerChanges]);

    useEffect(() => {
      function loadDimension(callback) {
        if (components.length > 0) {
     
          // console.log('components', components, aspectRatio);
          const dims = [];
          for(var i = 0; i < components.length; i++) {
            var obj = components[i];
            var aspectDims = obj.dimensions.filter((dimension) => dimension.display === aspectRatio);
            if (aspectDims.length > 0) {
              dims.push(aspectDims[0]);
            }
            if (i === (components.length - 1)) {
              callback(dims);
            }
            
          }
      }
    }


    // CHANGE ASPECT RATIO
    function setDimensions(dims) {
      handleCloseSettings();
        if (partnerChanges.length > 0) {
          var newDims = [...dims];
          // console.log('partnerChanges', partnerChanges, newDims, dims);
          partnerChanges.forEach(function (partnerItem, partnerIndex) {
          if (partnerItem['id'] !== undefined) {
            console.log('partnerItem', partnerItem, partnerItem['id'], partnerIndex, newDims);

            var dimPartnerIndex = newDims.findIndex(item => item.id === partnerItem['id']);
            if (dimPartnerIndex > -1) {
              if (partnerItem['x_pos']) {
                newDims[dimPartnerIndex]['x_pos'] = partnerItem['x_pos']
                newDims[dimPartnerIndex]['y_pos'] = partnerItem['y_pos']
              }
              if ((partnerItem['width'])&&(partnerItem['height'])) {
                newDims[dimPartnerIndex]['width'] = partnerItem['width']
                newDims[dimPartnerIndex]['height'] = partnerItem['height']
              }
              if (partnerItem['component_ordering']) {
                newDims[dimPartnerIndex]['component_ordering'] = partnerItem['component_ordering']
              }
              newDims[dimPartnerIndex]['typeName'] = partnerItem['typeName'];
            }
            // console.log('partnerIndex', partnerIndex, partnerChanges.length, partnerItem);
            if ((partnerIndex+1) === partnerChanges.length){
              
              setComponentDimensions(newDims);
            }
          }
          
  
          });
        } else {
          setComponentDimensions(dims);
        }
      }

      loadDimension(setDimensions);
      
    }, [aspectRatio]);

    useEffect(() => {
      console.log('componentDimensions', componentDimensions);

      var currPartnerChanges = [];
      componentDimensions.forEach(function (dimension, index) {
        if (dimension.is_locked) {
          var partnerIds = dimension.partner_ids;
          partnerIds.forEach(function (partnerId, index0) {
            
            var partnerChangesIndex = currPartnerChanges.findIndex(item => item.id === partnerId);
           
            if (partnerChangesIndex > -1) {
              currPartnerChanges[partnerChangesIndex] = dimension;
              //  console.log('currPartnerChanges:', currPartnerChanges, dimension);
            } else {
              var partnerObj = {};
              var newPartnerObj = Object.assign(partnerObj, dimension);
              newPartnerObj['id'] = partnerId;
              // console.log('newPartnerObj', partnerId, newPartnerObj);
              currPartnerChanges.push(newPartnerObj);
            }
          });
        }
        if (index === (componentDimensions.length - 1)) {
          setPartnerChanges(currPartnerChanges);
          // console.log('componentDimensions: setPartnerChanges', currPartnerChanges);
        }
      });

    }, [componentDimensions]);


    function handleComponentDimensions() {
      
      var activeSection = sections.find(section => (section.id === sectionId));
      // console.log('handleComponentDimensions', activeSection, sectionId, aspectRatio);
      if (activeSection) {
        // console.log('components, sectionID, aspectRatio', activeSection.components, sectionId, aspectRatio);
       
        setSection(activeSection);
        if (activeSection.components.length > 0) {
          setComponents(activeSection.components)
          var components = activeSection.components;
          var dims = [];
          for(var i = 0; i < components.length; i++) {
            var obj = components[i];
            
              
              var dim = obj.dimensions.filter((dimension) => dimension.display === aspectRatio);
              if (dim.length !== 0) {
                // console.log('obj', obj, dim.length);
                dims.push(dim[0]);
              }
              
              if (i === (components.length - 1)) {
                setComponentDimensions(dims);
              }
          }

        } else {
          setComponents([]);
          setComponentDimensions([]);
        }
      }

    }

     useEffect(() => {
       if (newComponentID) {
        const api_url = base_url + 'section-componentdimension-duplicate/';
        var formJSON = {
          'component_id': newComponentID
        }

        axios({
          method: 'POST',
          url: api_url,
          data: formJSON,
        })
        .then(response => {
              console.log('duplicate', response);
              fetchJSON(false);
              
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });
    
       }
    }, [newComponentID]);


    useEffect(() => {
      if (newComponentPopUpID) {
       const api_url = base_url + 'section-componentdimension-popup-duplicate/';
       var formJSON = {
         'component_id': newComponentPopUpID
       }

       axios({
         method: 'POST',
         url: api_url,
         data: formJSON,
       })
       .then(response => {
             console.log('duplicate', response);
             fetchJSON(false);
             setNewComponentPopUpID(null);
       })
       .catch(error => {
           console.log('error', error);
           if (error.response) {
           console.log(error.response.data);
           console.log(error.response.status);
           console.log(error.response.headers);
           } else if (error.request) {
           console.log(error.request);
           } else {
           console.log('Error', error.message);
           }
           console.log(error.config);
       });
   
      }
   }, [newComponentPopUpID]);

   function handlePostSave() {
    
      setUnSavedChanges(false);
      setNewAnimObj({});
      setShowNewAnimObj(false);
      setShowNewAnimObjReady(false);
      setShowNewStateAnimObj(false);

      var newValueInputs = document.getElementsByClassName('newValueInput');
      console.log('newValueInputs', newValueInputs);
      var i;
      for (i = 0; i < newValueInputs.length; i++) {
        newValueInputs[i].value = "";
        // console.log('handleAddNewAnimation', newValueInputs, newValueInputs[i], newValueInputs[i].value);
      }
      
      for(var i = 0; i < sectionChanges.length; i++) {
        if (sectionChanges[i].id === sectionId) {
          handleSectionSave(i);
        }
        
      }   

      var dimensions = componentDimensions;
      var saveIndex = componentDimensions.findIndex((dimension) => dimension.id === activeSettingId);

      // console.log('state heading save', dimensions[saveIndex].component_state_heading);

      if (dimensions[saveIndex]) {

        var dataJSON = {
          'typeName': dimensions[saveIndex].typeName,
          'width': dimensions[saveIndex].width,
          'height': dimensions[saveIndex].height,
          'x_pos': dimensions[saveIndex].x_pos,
          'y_pos': dimensions[saveIndex].y_pos,
          'background': dimensions[saveIndex].background,
          'component_ordering': dimensions[saveIndex].component_ordering,
          'component_link': dimensions[saveIndex].component_link,
          'component_link_heading': dimensions[saveIndex].component_link_heading,
          'component_state_heading': dimensions[saveIndex].component_state_heading,
          'is_popup': dimensions[saveIndex].is_popup,
          'is_locked': dimensions[saveIndex].is_locked,
          'active': dimensions[saveIndex].active
        }
        var isLast = true;
        handleResizeImg(dataJSON, dimensions[saveIndex].id, isLast);
        // console.log('dimensions[saveIndex].component_link_heading',  dimensions[saveIndex].component_link_heading)
        console.log('save animation', dimensions[saveIndex]['animations'])
        // Animation Edit Loop
        if (dimensions[saveIndex].is_locked === true) {
          if (dimensions[saveIndex].display_name === 'HD') {
            for(var j = 0; j < dimensions[saveIndex]['animations'].length; j++) {
              var animDataJSON = {
                'componentdimension': dimensions[saveIndex].id,
                'id': dimensions[saveIndex]['animations'][j].id,
                'property': dimensions[saveIndex]['animations'][j].property,
                'start': dimensions[saveIndex]['animations'][j].start,
                'end': dimensions[saveIndex]['animations'][j].end,
                'startParallax': dimensions[saveIndex]['animations'][j].startParallax,
                'endParallax': dimensions[saveIndex]['animations'][j].endParallax,
                'duration': dimensions[saveIndex]['animations'][j].duration,
                'delay': dimensions[saveIndex]['animations'][j].delay,
                'ease': dimensions[saveIndex]['animations'][j].ease,
                'is_state': false,
              }
              console.log('animDataJSON', animDataJSON);
              handleEditAnimation(animDataJSON);
            }
          }
        }  else {

          for(var j = 0; j < dimensions[saveIndex]['animations'].length; j++) {
            var animDataJSON = {
              'componentdimension': dimensions[saveIndex].id,
              'id': dimensions[saveIndex]['animations'][j].id,
              'property': dimensions[saveIndex]['animations'][j].property,
              'start': dimensions[saveIndex]['animations'][j].start,
              'end': dimensions[saveIndex]['animations'][j].end,
              'startParallax': dimensions[saveIndex]['animations'][j].startParallax,
              'endParallax': dimensions[saveIndex]['animations'][j].endParallax,
              'duration': dimensions[saveIndex]['animations'][j].duration,
              'delay': dimensions[saveIndex]['animations'][j].delay,
              'ease': dimensions[saveIndex]['animations'][j].ease,
              'is_state': false
            }
            console.log('animDataJSON', animDataJSON);
            handleEditAnimation(animDataJSON);
          }    

        }
      
        if (dimensions[saveIndex].is_locked === true) {
          if (dimensions[saveIndex].display_name === 'HD') {
          
            for(var j = 0; j < dimensions[saveIndex]['state_animations'].length; j++) {
      
            var animStateDataJSON = {
              'componentdimension': dimensions[saveIndex].id,
              'id': dimensions[saveIndex]['state_animations'][j].id,
              'property': dimensions[saveIndex]['state_animations'][j].property,
              'start': dimensions[saveIndex]['state_animations'][j].start,
              'end': dimensions[saveIndex]['state_animations'][j].end,
              'startParallax': dimensions[saveIndex]['state_animations'][j].startParallax,
              'endParallax': dimensions[saveIndex]['state_animations'][j].endParallax,
              'duration': dimensions[saveIndex]['state_animations'][j].duration,
              'delay': dimensions[saveIndex]['state_animations'][j].delay,
              'ease': dimensions[saveIndex]['state_animations'][j].ease,
              'is_state': true,
            }
            console.log('animStateDataJSON', animStateDataJSON);
            handleEditAnimation(animStateDataJSON);
          }
        } else {
          var animStateDataJSON = {
            'componentdimension': dimensions[saveIndex].id,
            'id': dimensions[saveIndex]['state_animations'][j].id,
            'property': dimensions[saveIndex]['state_animations'][j].property,
            'start': dimensions[saveIndex]['state_animations'][j].start,
            'end': dimensions[saveIndex]['state_animations'][j].end,
            'startParallax': dimensions[saveIndex]['state_animations'][j].startParallax,
            'endParallax': dimensions[saveIndex]['state_animations'][j].endParallax,
            'duration': dimensions[saveIndex]['state_animations'][j].duration,
            'delay': dimensions[saveIndex]['state_animations'][j].delay,
            'ease': dimensions[saveIndex]['state_animations'][j].ease,
            'is_state': true
          }
          console.log('animStateDataJSON', animStateDataJSON, j);
          handleEditAnimation(animStateDataJSON);
        }    
    }

      if (Object.keys(newAnimObj).length > 0) {
        var animJSON = newAnimObj;
        animJSON['componentdimension'] = activeSettingId;
        // console.log(animJSON, activeSettingId, 'animTest');
        handleAddAnimation(animJSON);
        setNewAnimObj({});

      }

      if (Object.keys(newStateAnimObj).length > 0) {
        var animJSON2 = newStateAnimObj;
        animJSON2['componentdimension'] = activeSettingId;
        // console.log(animJSON, activeSettingId, 'animTest');
        handleAddAnimation(animJSON2);
        setNewStateAnimObj({});
      }
    }

  
  }



  useEffect(() => {
    
   if (newComponentDimension) {
      console.log("new component, dimensions loaded", newComponentDimension, componentDimensions);
      fetchJSON(true);
    } 
   
  }, [newComponentDimension, componentDimensions]);
   
    
    useEffect(() => {
      handleComponentDimensions();
      
      if (newComponentDimension) {
        handleNewComponent();
      }
    }, [sections, sectionId]);

    useEffect(() => {
      var activeSectionObj = sections.filter(section => (section.id === sectionId))[0];
      setActiveSectionObj(activeSectionObj);
      console.log('activeSectionObj', activeSectionObj);
    }, [sectionId]);

    useEffect(() => {
      if (showNewAnimObjReady === true) {
        console.log('newAnimObj', newAnimObj);  
        setShowNewAnimObj(true);  
      } 
    }, [showNewAnimObjReady]);


    useEffect(() => {
      if (triggerSaveForm === true) {
        handlePostSave();
        setTriggerSaveForm(false);
      }
      
    }, [triggerSaveForm, unSavedChanges, componentDimensions])

    function DraggableEventHandler(e, data) {
        console.log(e, data);
    }

    function handleNarrativeActive(e) {
      if (narrativeActive) {
        var isActive = false;
        setNarrativeActive(isActive);
      } else {
        var isActive = true;
        setNarrativeActive(isActive);
      }
    }

    function handleNarrativeTitle(e) {
      setNarrativeTitle(e.target.value);
      setUnSavedChanges(true);
    }

    function showSettings(objID, index, isSave) {
        // console.log("showSettings", objID);
        setActiveSettingId(objID);
        if (isSave) { 
          var width = document.getElementById('width_dim_' + objID).value;
          var height = document.getElementById('height_dim_' + objID).value;
          var x_pos = document.getElementById('x_pos_dim_' + objID).value;
          var y_pos = document.getElementById('y_pos_dim_' + objID).value;
          var component_ordering = document.getElementById('ordering_dim_' + objID).value;
          var component_link = document.getElementById('link_' + objID).value;
          var component_link_heading = document.getElementById('link_heading_' + objID).value;
          try {
            var component_state_heading = document.getElementById('state_heading_' + objID).value;
          } catch {
            var component_state_heading = null
          }
          
          console.log('showSettings', objID, index, component_state_heading);

          var newComponents = [...componentDimensions];

          var newIndex = newComponents.findIndex(item => item.id === objID);
          var newObj = newComponents[newIndex];

          if (!width) {
            var width = newComponents[index].width
          }
          if (!height) {
            var height = newComponents[index].height
          }
          if (!x_pos) {
            var x_pos = newComponents[index].x_pos
          }
          if (!y_pos) {
            var y_pos = newComponents[index].y_pos
          }
          if (!component_ordering) {
            var component_ordering = newComponents[index].component_ordering
          }
          if (!component_link) {
            var component_link = newComponents[index].component_link
          }
          if (!component_link_heading) {
            var component_link_heading = newComponents[index].component_link_heading
          }
          if (!component_state_heading) {
            var component_state_heading = newComponents[index].component_state_heading
          }
        
          newObj['width'] = parseInt(width);
          newObj['height'] = parseInt(height);
          newObj['x_pos'] = parseInt(x_pos);
          newObj['y_pos'] = parseInt(y_pos);
          newObj['component_ordering'] = parseInt(component_ordering);
          newObj['component_link'] = component_link
          newObj['component_link_heading'] = component_link_heading
          newObj['component_state_heading'] = component_state_heading

          // console.log('component_link_heading', component_link_heading, newComponents);
          setComponentDimensions(newComponents);
          setUnSavedChanges(true);
          setTriggerSaveForm(true);
        } else {
          setShowManifest(true);
        }
    }

    function handleSectionScrollOffset(e) {

      const timeout = setTimeout(() => {
        
        var scroll_offset = e.target.value;
        var newSections = [...sections];
        var objIndex = newSections.findIndex(x => x.id ===sectionId);
        var newObj = newSections[objIndex];
        var changesCopy = [ ...sectionChanges ];
        var sectionObj = {}
        sectionObj['id'] = sectionId;
        sectionObj['scroll_offset'] = scroll_offset
        sectionObj['ordering'] = newObj['ordering'];
        sectionObj['title'] = newObj['title'];
        sectionObj['active'] = newObj['active'];
        changesCopy.push(sectionObj);

        setSectionChanges(changesCopy);
        setSections(newSections);
        setUnSavedChanges(true);
        setSectionId(sectionId);

      }, 600);
      return () => {
        clearTimeout(timeout);
      };
      
    }


    function handleSectionOrdering(e) {

      const timeout = setTimeout(() => {
        
        var ordering = e.target.value;
        var newSections = [...sections];
        var objIndex = newSections.findIndex(x => x.id ===sectionId);
        var newObj = newSections[objIndex];
        newObj['ordering'] = ordering;

        var changesCopy = [ ...sectionChanges ];
        var sectionObj = {}
        sectionObj['id'] = sectionId;
        sectionObj['ordering'] = parseInt(ordering);
        sectionObj['title'] = newObj['title'];
        sectionObj['scroll_offset'] = newObj['scroll_offset'];
        sectionObj['active'] = newObj['active'];
        changesCopy.push(sectionObj);

        setSectionChanges(changesCopy);
        setSections(newSections);
        setUnSavedChanges(true);
        setSectionId(sectionId);

      }, 600);
      return () => {
        clearTimeout(timeout);
      };
      
    }

    

    function handleSectionTitle(e) {
      const timeout = setTimeout(() => {

      var title = e.target.value;
      var newSections = [...sections];
      var objIndex = newSections.findIndex(x => x.id ===sectionId);
      var newObj = newSections[objIndex];

      var changesCopy = [ ...sectionChanges ];
      var sectionObj = {}
      sectionObj['id'] = sectionId;
      sectionObj['ordering'] = newObj['ordering'];
      sectionObj['scroll_offset'] = newObj['scroll_offset'];
      sectionObj['title'] = title;
      sectionObj['active'] = newObj['active'];
      changesCopy.push(sectionObj);

      setSectionChanges(changesCopy);
      setSections(newSections);
      setUnSavedChanges(true);
      setSectionId(sectionId);

    }, 1000);
    return () => {
      clearTimeout(timeout);
    };

    }

    function handleSectionActive(active) {
    
      var newSections = [...sections];
      var objIndex = newSections.findIndex(x => x.id ===sectionId);
      var newObj = newSections[objIndex];
      newObj['active'] = active;

      var changesCopy = [ ...sectionChanges ];
      var sectionObj = {}
      sectionObj['id'] = sectionId;
      sectionObj['ordering'] = newObj['ordering'];
      sectionObj['title'] = newObj['title'];
      sectionObj['scroll_offset'] = newObj['scroll_offset'];
      sectionObj['active'] = active;

      console.log('sectionObj', sectionObj);

      changesCopy.push(sectionObj);

      setSectionChanges(changesCopy);
      setSections(newSections);
      setUnSavedChanges(true);
      setSectionId(sectionId);

    }

    function handleCloseSettings() {
      if (activeSettingId) {
        setActiveSettingId(null);
        setShowManifest(false);
      } 
      
    }

    function handleAspectRatio(index) {
        setAspectRatios(index);
        
    }

    function handleShowFullCanvas(e) {
      if (showFullCanvas) {
        setShowFullCanvas(false);
      } else {
        setShowFullCanvas(true);
      }
      
    }

    function handleResizeImg(dataJSON, objID, isLast) {
      // handleStateMediaEditHeading

      var objIndex = componentDimensions.findIndex(x => x.id ===objID);
      var dimObj = componentDimensions[objIndex];

      console.log("handleResizeImg dims", dimObj);

      const api_url = base_url + 'component-dimension/' + objID + '/';
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          // headers: {
          //     Authorization: 'Token ' + Cookies.get('ud_token')
          // },
      })
      .then(response => {
          console.log('handleResizeImg, component dim save:', response);
          if (isLast === true) {
            fetchSection(sectionId)
          }
          
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleAddAnimation(dataJSON) {
      const api_url = base_url + 'componentdimension-animation-add/';
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          // headers: {
          //     Authorization: 'Token ' + Cookies.get('ud_token')
          // },
      })
      .then(response => {
          console.log('handleAddAnimation', response);
          fetchSection(sectionId)
          
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }

    function handleEditAnimation(dataJSON) {
      const api_url = base_url + 'componentdimension-animation-edit/';
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          // headers: {
          //     Authorization: 'Token ' + Cookies.get('ud_token')
          // },
      })
      .then(response => {
          console.log('handleEditAnimation', response);
          fetchSection(sectionId)
          
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }



    function handleDeleteAnim(objID) {
      var dataJSON = {
        'animation_id': objID
      }
      // console.log(dataJSON);
      const api_url = base_url + 'componentdimension-animation-delete/';
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON,
          // headers: {
          //     Authorization: 'Token ' + Cookies.get('ud_token')
          // },
      })
      .then(response => {
          console.log('handleDeleteAnimation', response);
          fetchSection(sectionId)
          
      })
      .catch(error => {
          console.log('error', error.response);
      });
    }



    function handleReloadSection(response) {
      fetchJSON(false);
    }
    

    function handleSectionSave(i) {
      const api_url = base_url + 'section-edit/';
      var dataJSON = {
        'id': sectionChanges[i].id,
        'ordering': sectionChanges[i].ordering,
        'scroll_offset': sectionChanges[i].scroll_offset,
        'title': sectionChanges[i].title,
        'active': sectionChanges[i].active
      }
      console.log('handleSectionSave', dataJSON, api_url);
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON
      })
      .then(response => {
          console.log('handleSectionSave', response);
          handleReloadSection(response);
      })
      .catch(error => {
          console.log('error', error.response);
      });

    }


    
 
    function handleFormSave(e) {
      console.log('SAVED', e);

      handlePostSave();

    }

    function handleAddSection(e) {
      const api_url = base_url + 'section-add/';
      var dataJSON = {
        'narrative_id': narrative.id,
      }
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON
      })
      .then(response => {
        fetchJSON(false);
        console.log('handleAddSection', response.data)
        setSectionId(response.data);
      })
      .catch(error => {
          console.log('error', error.response);
      });



    }

    function handleSectionScreenShot(e) {
      const api_url = base_url + 'section-screenshot/';
      setShowGenerateScreenshot(false);
      var dataJSON = {
        'id': sectionId,
      }
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON
      })
      .then(response => {
        fetchJSON(false);
        console.log('handleSectionScreenShot', response.data)
        setSectionId(response.data);
        setShowGenerateScreenshot(true);
      })
      .catch(error => {
          console.log('error', error.response);
      });



    }

    

    function handleDeleteMedia(objID) {
      showSettings(objID, 0, false);
      const api_url = base_url + 'section-componentdimension-delete/';
      var dataJSON = {
        'displaycomponent_id': objID,
      }
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON
      })
      .then(response => {
        fetchJSON(false);
        setUnSavedChanges(true);
        setActiveSettingId(null);

      })
      .catch(error => {
          console.log('error', error.response);
          
      });



    }


    function handleDeletePopUp(objID, parentID) {
      showSettings(parentID, 0, false);
      const api_url = base_url + 'section-componentdimension-delete/';
      var dataJSON = {
        'displaycomponent_id': objID,
      }
      axios({
          method: 'POST',
          url: api_url,
          data: dataJSON
      })
      .then(response => {
        fetchJSON(false);
        setUnSavedChanges(true);
        setActiveSettingId(null);

      })
      .catch(error => {
          console.log('error', error.response);
      });



    }
    function handleConfirmDeleteSection(){

      setShowDeleteSection(true);
  
    }
  

    function handleDeleteSection(choice) {
      console.log('handleSectionDelete', sectionId, choice);
      if (choice === 'cancel') {
        setShowDeleteSection(false);
      } else {
        const api_url = base_url + 'section-delete/';
        var dataJSON = {
          'section_id': sectionId,
        }
        axios({
            method: 'POST',
            url: api_url,
            data: dataJSON
        })
        .then(response => {
          fetchJSON(false);
          setSectionId(sections[0]['id']);
          setShowDeleteSection(false);
        })
        .catch(error => {
            console.log('error', error.response);
        });

      }
      


    }


    function handleShowMediaDropDown() {
      if (showMediaDropDown) {
        setShowMediaDropDown(false);
      } else {
        setShowMediaDropDown(true);
      }
    }
    

    function handleMediaChoice(format) {
      setAcceptValues(null);
      
      
      function handleMediaChoiceValue(format, callback) {
        setMediaChoice(format);
        if ((format === 'img')||(format === 'statePNGButton')) {
          setAcceptValues("image/png, image/jpeg, image/webp");
        } 
        if (format === 'vid') {
          setAcceptValues("video/mp4");
        }
        if (format==='svg') {
          setAcceptValues("image/svg+xml");
        }
        
        callback();
      }

      function setupFileUpload() {
        var fileElem = document.getElementById('fileUpload');
        setTimeout(() => {
          fileElem.click();
        }, 150);
      }

      handleMediaChoiceValue(format, setupFileUpload);
    }

    function handleMediaAdded(isBackground) {
      // console.log('handleMediaAdded', isBackground);
      var fileList = document.getElementById('fileUpload').files;
      // console.log('handleMediaAdded', sectionId);
      var arrayLength = fileList.length;
      for (var i = 0; i < arrayLength; i++) {
        var mediaData = {
          'display': aspectRatio,
          'section_id': sectionId,
          'file': fileList[i],
          'format': mediaChoice
        }
        var currNewMedia = [...newMedia];
        currNewMedia.push(mediaData);
        setNewMedia(currNewMedia);

        const api_url = base_url + 'section-component-add/';

        setShowLoader(true);
        var formData = new FormData();
        var fileField = 'image';
        if (mediaChoice === 'svg') {
          var fileField = 'svg';
        }
        if (mediaChoice === 'vid') {
          var fileField = 'video';
        }
        if (mediaChoice === 'statePNGButton') {
          var fileField = 'image';
        }

        formData.append(fileField, fileList[i], fileList[i].name) 
        formData.append('section_id', section.id);
        formData.append('display_id', 1);
        formData.append('file_format', mediaChoice);
        if (isBackground) {
          formData.append('background', true);
        } else {
          formData.append('background', false);
        }

        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment; filename=' + fileList[i].name,
            },
        })
        .then(response => {
              console.log('handleMediaAdded', response, isBackground);
              setAcceptValues(null);
              setMediaChoice(null);
              var fileList = document.getElementById('fileUpload').files;
              fileList.files = [];
              // setShowMediaDropDown(false);
              setNewComponentID(response.data['component_id']);
              
              var newJSON = {
                'id': response.data['dimension_id'], 
                'is_background': isBackground 
              };
              setNewComponentDimension(newJSON);
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });

        
    
        
      }
    }

    function handleMediaReplace(isBackground, mediaChoice, componentdimension_id, e, isFileOn) {
      // var fileList = document.getElementById('fileReplace').files;
      var fileList = e.target.files;
      console.log('handleMediaReplace', componentdimension_id, fileList);
      var arrayLength = fileList.length;
      for (var i = 0; i < arrayLength; i++) {
        var mediaData = {
          'display': aspectRatio,
          'componentdimension_id': componentdimension_id,
          'file': fileList[i],
          'format': mediaChoice
        }
        var currNewMedia = [...newMedia];
        currNewMedia.push(mediaData);
        setNewMedia(currNewMedia);

        const api_url = base_url + 'section-component-replace/';

        setShowLoader(true);
        var formData = new FormData();
        var fileField = 'image';
        if (mediaChoice === 'svg') {
          var fileField = 'svg';
        }
        if (mediaChoice === 'vid') {
          var fileField = 'video';
        }
        if (isFileOn) {
          var fileField = 'image_on';
        }

        formData.append(fileField, fileList[i], fileList[i].name) 
        formData.append('id', componentdimension_id);
        formData.append('display_id', 1);
        formData.append('file_format', mediaChoice);
        if (isBackground) {
          formData.append('background', true);
        } else {
          formData.append('background', false);
        }

        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment; filename=' + fileList[i].name,
            },
        })
        .then(response => {
              console.log('handleMediaReplaced', response, isBackground);
              // window.location.reload();
              fetchJSON(false);
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });

        
    
        
      }
    }

    function handleStateMediaAddReplace(dimensionId, e, stateId, stateHeading) {
      console.log('handleStateMediaAddReplace', dimensionId, e, stateId);
      var fileList = e.target.files;
      var arrayLength = fileList.length;
      for (var i = 0; i < arrayLength; i++) {
        const api_url = base_url + 'componentdimension-stateimg-add/';

        setShowLoader(true);
        var formData = new FormData();
        formData.append('image', fileList[i], fileList[i].name) 
        formData.append('componentdimension', dimensionId);
        formData.append('state_id', stateId)

        console.log('state_id', stateId)
        formData.append('state_heading', stateHeading)

        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
                'Content-Disposition': 'attachment; filename=' + fileList[i].name
            },
        })
        .then(response => {
              console.log('handleStateMediaAddReplace', response);
              // window.location.reload();
              fetchJSON(false);
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });

       
      }
    }

    function handleStateMediaEditHeading(dimensionId, e, stateId, stateHeading) {
      console.log('handleStateMediaEditHeading', dimensionId, e, stateId);
      var fileList = e.target.files;
      var arrayLength = fileList.length;
      for (var i = 0; i < arrayLength; i++) {
        const api_url = base_url + 'componentdimension-stateimg-edit-heading/';

        setShowLoader(true);
        var formData = new FormData();
        formData.append('componentdimension', dimensionId);
        formData.append('state_id', stateId)
        // var stateHeading = document.getElementById("state_heading_" + stateId).value;
        // console.log('stateHeading', stateHeading)
        formData.append('state_heading', stateHeading)
        axios({
            method: 'POST',
            url: api_url,
            data: formData
        })
        .then(response => {
              console.log('handleStateMediaEditHeading', response);
              // window.location.reload();
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });

       
      }
    }


    function handleShowAnimDropDown() {
      if (showAnimDropDown) {
        setShowAnimDropDown(false);
      } else {
        setShowAnimDropDown(true);
      }
    }


    function handleAnimChoice(typeName, objID){
      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      if (typeName !== 'none') {
        newObj['typeName'] = typeName;
      } else {
        newObj['typeName'] = "";
      }
      setShowAnimDropDown(false);
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);
    }

    function handlePopUpAdded(format, objID) {
      var fileList = document.getElementById('popUpload').files;
      // console.log('handleMediaAdded', sectionId);
      var arrayLength = fileList.length;
      for (var i = 0; i < arrayLength; i++) {
        var mediaData = {
          'display': aspectRatio,
          'section_id': sectionId,
          'file': fileList[i],
          'format': format
        }
        var currNewMedia = [...newMedia];
        currNewMedia.push(mediaData);
        setNewMedia(currNewMedia);

        const api_url = base_url + 'section-component-popup-add/';

        setShowLoader(true);
        var formData = new FormData();

        var fileField = 'image';
        
        formData.append(fileField, fileList[i], fileList[i].name) 
        formData.append('section_id', section.id);
        formData.append('requesting_parent_id', activeSettingId);
        formData.append('display_id', 1);
        formData.append('file_format', format);



        axios({
            method: 'POST',
            url: api_url,
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Disposition': 'attachment; filename=' + fileList[i].name,
            },
        })
        .then(response => {
              console.log('handleAddPopUpMedia', response);
              setAcceptValues(null);
              setMediaChoice(null);
              setShowMediaDropDown(false);
              fetchJSON(false);
              setNewComponentPopUpID(response.data['component_id']);
              
        })
        .catch(error => {
            console.log('error', error);
            if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
            console.log(error.config);
        });

        
    
        
      }
    }


    function handleAddPopup(objID, format) {
      console.log('handleAddPopup', objID);
      if (format === 'img') {
        setAcceptValues("image/png, image/jpeg, image/webp");
      } 
      if (format === 'vid') {
        setAcceptValues("video/mp4");
      }
      if (format==='svg') {
        setAcceptValues("image/svg+xml");
      }
      var fileElem = document.getElementById('popUpload');
      setTimeout(() => {
        fileElem.click();
      }, 150);

    }

    function handleManifestFileClick(objID) {
      // console.log('bounce', objID);
      // var card = document.getElementById(objID);
      // card.classList.add("bounce");
      // setTimeout(() => {
      //   card.classList.remove("bounce");
      // }, 250);
    }
    

    function handleBackgroundActive(objID, index) {
      console.log(objID, index);
      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      var bkg = newObj['background'];
      if (bkg) {
        newObj['background'] = false;

      } else {
        newObj['background'] = true;
        newObj['x_pos'] = 0;
        newObj['y_pos'] = 0;
        newObj['component_ordering'] = 0;
      }
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);
      
      
    }

    function handleShowStateOptions(objID) {
      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      newObj['has_state_images'] = true;
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);
    }

    function handleAspectLock(objID, index) {
      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      var is_locked = newObj['is_locked'];

      console.log('isLocked', is_locked);
      if (is_locked === true) {
        newObj['is_locked'] = false;

      } else {
        newObj['is_locked'] = true;
      }
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);
    }

    function handleOnActive(objID, index) {
      // console.log(objID, index);
      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === objID);
      var newObj = newComponents[newIndex];
      var bkg = newObj['active'];
      if (bkg) {
        newObj['active'] = false;

      } else {
        newObj['active'] = true;
      }
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);
    }


    function containsObject(objID, list) {
      if (list)
      {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].display === objID) {
              if (list[i].width) {
                return (list[i].width * 0.075) + 'px';
              } else {
                return 'auto';
              }
                
            }
        }
      }
  
      return '155px';
  }

  function getSectionBackgroundImage(objID, list) {
    if (list) {
    var i;
    for (i = 0; i < list.length; i++) {
      // console.log('list[i]', list[i]);
        if (list[i].display === objID) {
            if (list[i].screenshot) {
              
              return list[i].screenshot
            } else {
              return list[i].image
            }
            
        }
    }

    return imagePlaceholder;
  }
  }


  function handleMenuBtn() {
      if (menuOpen) {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
  }

  function handleBackBtn(step){
    if (step === 'step1') {
      setShowError(true);
    } 
    if (step === 'cancel') {
      setShowError(false);
    } 
   
    if (step === 'force') {
      var url = base_admin_url + '/admin/content/narrative/';
      window.location.href = url;
    } 
   
   
  }

  function handleInputDropDownFocus(target){
    var dropdown_id = target.id;
    var dropdown = document.getElementById(dropdown_id + '_dd');

    console.log(target.classList, dropdown)

    if (target.classList.contains('on')) {
      target.classList.remove('on');
      if (dropdown) {
        dropdown.classList.add('off');
      }
    } else {
      var onInputs = document.querySelectorAll('.valueInput.on');
      var onDropDowns = document.querySelectorAll('.inputDropDown');
      var i;
      for (i = 0; i < onInputs.length; i++) {
        onInputs[i].classList.remove('on');
      }
      var j;
      for (j = 0; j < onDropDowns.length; j++) {
        onDropDowns[j].classList.add('off');
      }
      
      target.classList.add('on');
      if (dropdown) {
        dropdown.classList.remove('off');
      }
      
    }
  }

  function handleInputFocus(e){

   

    var target = e.target;
    var existingVal = target.value;

    if (!existingVal) {
      var val = target.placeholder;
      target.value = val;
    }
    // console.log('handleInputFocus', target, val, e)
   
    target.select();
    handleInputDropDownFocus(target);
    
  }



  function handleAddNewAnimation() {
    var animObj = {};
    animObj['duration'] = '';
    animObj['delay'] = '';
    animObj['ease'] = 'default';
    animObj['start'] = '';
    animObj['end'] = '';
    animObj['is_state'] = false;
    setNewAnimObj(animObj);
    setShowNewAnimObj(true);
   
    var newValueInputs = document.getElementsByClassName('newValueInput');
    console.log('newValueInputs', newValueInputs);
    var i;
    for (i = 0; i < newValueInputs.length; i++) {
      newValueInputs[i].value = "";
      // console.log('handleAddNewAnimation', newValueInputs, newValueInputs[i], newValueInputs[i].value);
    }
    
  }

  function handleNewAnimValue(key, value, e) {
    if (Object.keys(newAnimObj).length > 0) {
      var animObj = Object.assign({}, newAnimObj);
    } else {
      var animObj = {};
    }
    animObj[key] = value;
    setNewAnimObj(animObj);

    console.log('animObj', animObj, key, value);

    if (key === 'property') {
      // animPropRef.current = value;
      setAnimPropOn(value);
      
    }

    // console.log('handleNewAnimValue', handleNewAnimValue);


  }

  function handleAddNewStateAnimation() {
    var animObj = {};
    animObj['duration'] = '';
    animObj['delay'] = '';
    animObj['ease'] = 'default';
    animObj['start'] = '';
    animObj['end'] = '';
    animObj['is_state'] = true;
    setNewStateAnimObj(animObj);
    setShowNewStateAnimObj(true);
   
    var newValueInputs = document.getElementsByClassName('newValueInput');
    console.log('newValueInputs', newValueInputs);
    var i;
    for (i = 0; i < newValueInputs.length; i++) {
      newValueInputs[i].value = "";
      // console.log('handleAddNewAnimation', newValueInputs, newValueInputs[i], newValueInputs[i].value);
    }
    
  }

  function handleNewStateAnimValue(key, value) {
    if (Object.keys(newStateAnimObj).length > 0) {
      var animObj = Object.assign({}, newStateAnimObj);
    } else {
      var animObj = {};
    }
    animObj[key] = value;
    setNewStateAnimObj(animObj);
    console.log('handleNewStateAnimValue', animObj)



  }

  // (Object.keys(newAnimObj).length > 0)

 

  function handleAnimValue(componentID, animationID, key, value, e) {
    

      var newComponents = [...componentDimensions];
      var newIndex = newComponents.findIndex(item => item.id === componentID);
      var newObj = newComponents[newIndex];
      var newAnimIndex = newObj['animations'].findIndex(item => item.id === animationID);
      var newAnimObj = newObj['animations'][newAnimIndex];
      newAnimObj[key] = value; 

    
      setComponentDimensions(newComponents);
      setUnSavedChanges(true);

      var dropdowns = document.getElementsByClassName('inputDropDown');
      console.log('dropdown', dropdowns);
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.add('off');
      }

      e.target.classList.remove('on');
      
      // console.log('key', key, animPropRef.current);
      
      
   
  }

  function handleDropDownValue(e, value, id, id2, componentID, animationID, key) {
    console.log('handleDropDownValue', e, value)
    let elem = document.getElementById(id);
    elem.value = value;

    let elem2 = document.getElementById(id2);
    elem2.classList.add('off');

    var onInputs = document.querySelectorAll('.valueInput.on');
    var i;
    for (i = 0; i < onInputs.length; i++) {
      onInputs[i].classList.remove('on');
    }
    
    if (componentID && animationID) {
      handleAnimValue(componentID, animationID, key, value, e)
    } else {
      handleNewAnimValue(key, value, e);
    }
    
  }

  function handleStateAnimValue(componentID, animationID, key, value) {
    

    var newComponents = [...componentDimensions];
    var newIndex = newComponents.findIndex(item => item.id === componentID);
    var newObj = newComponents[newIndex];
    var newAnimIndex = newObj['state_animations'].findIndex(item => item.id === animationID);
    var newAnimObj = newObj['state_animations'][newAnimIndex];
    newAnimObj[key] = value; 

    setComponentDimensions(newComponents);
    setUnSavedChanges(true);
 
}

  function handleSwiperClick(objID){
    handleCloseSettings();
    setSectionId(objID);
    
  }

  function runAnimPreset(obj, componentDimensionId, isLocked, displayName, partnerIds) {
    // console.log('runAnimPreset', obj['presetitems']);
    let presets = obj['presetitems'];
    presets.forEach((item) => {
    //  console.log('item', item, componentDimensionId, isLocked, displayName); 

     var animDataJSON = {
      'componentdimension': componentDimensionId,
      'property': item.property,
      'start': item.start,
      'end': item.end,
      'startParallax': item.startParallax,
      'endParallax': item.endParallax,
      'duration': item.duration,
      'delay': item.delay,
      'ease': item.ease,
      'is_state': false,
    }

  
     if (isLocked === true) {
      if (displayName === 'HD') {
        handleAddAnimation(animDataJSON);
        partnerIds && partnerIds.forEach((id) => {
          animDataJSON['componentdimension'] = id;
          // console.log('id', id, animDataJSON);
          handleAddAnimation(animDataJSON);
        });
        

      }
    } else {
      handleAddAnimation(animDataJSON);
    }

    })
  }

    return (
        <section>
        {(showLoader)?(<div id="loading"><img src={Loader} alt="" /></div>):null}
        {(showError)?(<div id="errorPopup">Unsaved Changes are you sure? <div className="fullRow errorRow"><span onClick={() => handleBackBtn('cancel')}>[Cancel]</span> <span onClick={() => handleBackBtn('force')}>[Continue]</span></div></div>):null}
        {(showDeleteSection)?(<div id="errorPopup">Are you sure you want to delete this page? <div className="fullRow errorRow"><span onClick={() => handleDeleteSection('cancel')}>[Cancel]</span> <span onClick={() => handleDeleteSection('continue')}>[Continue]</span></div></div>):null}

        <div className={"formPage " + (showLoader)?(null):(' ready')}>
        
          {(narrative) ? 
            <div className="fieldSet">
             <div className="leftArrow" onClick={() => handleBackBtn((unSavedChanges)?('step1'):('force'))}><img src={LeftArrow} alt="leftArrow" /> Back</div>
              <div className="sectionRows">
              <div className="fieldRow sectionHeading">

              {sections.filter(section => (section.id === sectionId)).map(activeSection => (
                <div className="sectionHeading" id={"section_" + activeSection.id} key={'sectionHeading_' + activeSection.id}>
                <div className="fieldRow">
                    <div className="errors"></div>
                    <input type="text" id="sectionTitle" defaultValue={activeSection.title} onChange={(e) => handleSectionTitle(e)} />
                </div>
                <div className="fieldRow">
                  <span className="sectionLabel">Scroll Offset:</span>
                  <input type="text" onChange={(e) => handleSectionScrollOffset(e)} placeholder={activeSection.scroll_offset} id={'section_scroll_offset_' + activeSection.id} />
                </div>
                <div className="fieldRow">
                  <span className="sectionLabel">Ordering:</span>
                  <input type="number" onChange={(e) => handleSectionOrdering(e)} placeholder={activeSection.ordering} id={'section_ordering_' + activeSection.id} />
                </div>
  
                <div className={"fieldRow" + ((showManifest)?(' off'):(null))}>
                  <div className={"switch" + ((activeSection.active) ? " on" : " off")} id={"sectionActive_" + activeSection.id} onClick={(e) => handleSectionActive((activeSection.active)?(false):(true))}>
                      <div className="switchLabel">{((activeSection.active) ? " On" : " Off")}</div>
                      <div className="dot"></div>
                  </div>
                </div>
  
               
              
                </div>
  
              ))}

              </div>
                <div className="fieldRow">
                  {(section)?(
                   ((section.has_background) ? (
    
                      <div className="fieldRow">
                          <ul className="dropDown">
                            <li>
                              <span className="dropDownBtn" onClick={() => handleShowMediaDropDown()}>
                                Add Media
                                <svg xmlns="http://www.w3.org/2000/svg" id="downArrow" width="21" height="12.968" viewBox="0 0 21 12.968">
                                  <path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform={(showMediaDropDown)?('translate(0 0) rotate(0)'):('translate(21 12.968) rotate(180)')} fill="#fff"/>
                                </svg> 
                                </span>
                              <ul className={"mediaDropDown " + ((showMediaDropDown) ? ('on') : ('off'))}>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('img')}>Image (PNG or JPG)</li>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('vid')}>Video</li>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('svg')}>SVG</li>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('statePNGButton')}>State Button</li>
                              </ul>
                            </li>
                          </ul>
                          <input type="file" id="fileUpload" accept={acceptValues} onChange={() => handleMediaAdded(false)} multiple={false} />
                      </div>
       

                  ):(

                    <div className="fieldRow">
                          <ul className="dropDown">
                            <li>
                              <span className="dropDownBtn" onClick={() => handleShowMediaDropDown()}>
                                Add Background
                                <svg xmlns="http://www.w3.org/2000/svg" id="downArrow" width="21" height="12.968" viewBox="0 0 21 12.968">
                                  <path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform={(showMediaDropDown)?('translate(0 0) rotate(0)'):('translate(21 12.968) rotate(180)')} fill="#fff"/>
                                </svg> 
                                </span>
                              <ul className={"mediaDropDown " + ((showMediaDropDown) ? ('on') : ('off'))}>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('img')}>Image (PNG or JPG)</li>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('vid')}>Video</li>
                                <li className="mediaSelect" onClick={() => handleMediaChoice('svg')}>SVG</li>
                              </ul>
                            </li>
                          </ul>
                          <input type="file" id="fileUpload" accept={acceptValues} onChange={() => handleMediaAdded(true)} multiple={false} />
                      </div>
                    
                    ))

                  ):(null)}
              </div>

         

              </div>
              
             
              
              <div className="fieldRow saveRow">
                  <button className={(unSavedChanges) ? ("btn unsaved") : ("btn")} onClick={(e) => handleFormSave(e)}>Save Page</button>
              </div>

              <div className="fieldRow preview">
                  <a href={ preview_url + "/narrative/" + narrative.id + "?slide=" + sectionId + "&nav=closed"} target="_blank" rel="noreferrer">
                    Preview
                    <img src={OpenLink} className="icon" alt="" />  
                  </a>
              </div>
              <div className="details">
                    
                <a href={base_admin_url + '/api/narrative/' + narrative.id + '/' + sectionId + '/'} target="_blank" rel="noreferrer">Page JSON • {(sectionId)?(sectionId):(null)}</a>
                <a href={base_admin_url + '/api/narrative/' + narrative.id + '/'} target="_blank" rel="noreferrer">Narrative JSON</a>
              {(showGenerateScreenshot) ? <div className="hover" onClick={(e) => handleSectionScreenShot(e)}>Generate Screenshot</div> : null }
              </div>
            </div> : null}

            <div id="canvasDetails">
              <div
                  onClick={(e) => handleAspectRatio(2) }
                  className={
                      "displayName" + (aspectRatio === 2 ? " on" : " off")
                  }
              >
                Tablet (4:3)
              </div>
              <div
                  onClick={(e) => handleAspectRatio(1) }
                  className={
                      "displayName" + (aspectRatio === 1 ? " on" : " off")
                  }
              >
                HD (16:9)
              </div>
              <div
                  onClick={(e) => handleShowFullCanvas(e) }
                  className={
                      "displayName" + (showFullCanvas ? " on" : " off")
                  }
              >
                [ Full ]
              </div>
            </div>
            <div className={(activeSettingId)?("canvasFormWrap inFocus"):("canvasFormWrap offFocus")}>
              <div className={"canvasForm aspectRatio_" + (aspectRatio) + ((showFullCanvas) ? " showFull":"")} id={"canvasForm_canvas1"}>
                <div className="clipPath">
                  {componentDimensions
                    .sort((a, b) => a.component_ordering > b.component_ordering ? 1 : -1)
                    .filter(file => (file.active === true))
                    .map((dimension, i) => 
                   (dimension) ?
                    (<Rnd
                      // onDoubleClick={(e) => handleComponentDblClick("canvasForm1_draggable_" + dimension.id)}
                      onDoubleClick={() => showSettings(dimension.id, i, false)}
                      id={"canvasForm1_draggable_" + dimension.id}
                      position={{
                        x: dimension.x_pos * 0.463, 
                        y: dimension.y_pos * 0.463
                      }}
                      size={{
                        width: dimension.width * 0.463 ,  
                        height: dimension.height * 0.463
                      }}
                      key={'draggable_' + dimension.id}
                      lockAspectRatio={true}
                      enableResizing={(dimension.background)?(false):(true)}
                      disableDragging={(dimension.background)?(true):(false)}
                      onDragStop = {(e, d) => { 
                        var newComponents = [ ...componentDimensions ];
                        var newObj = newComponents.find(obj => (obj.id === dimension.id));
                        // var newObj = newComponents[i]
                        newObj['x_pos'] = parseInt(d.x/0.463);
                        newObj['y_pos'] = parseInt(d.y/0.463);
                        document.getElementById('x_pos_dim_' + dimension.id).value = newObj['x_pos'];
                        document.getElementById('y_pos_dim_' + dimension.id).value = newObj['y_pos'];

                        setComponentDimensions(newComponents);
                        setUnSavedChanges(true);
                      }}
                      onResizeStop={(e, direction, ref, delta, position) => {
                        var newComponents = [ ...componentDimensions ];
                        var newObj = newComponents.find(obj => (obj.id === dimension.id));
                        newObj['width'] = parseInt(ref.offsetWidth/0.463);
                        newObj['height'] = parseInt(ref.offsetHeight/0.463);
                        newObj['x_pos'] = parseInt(position.x/0.463);
                        newObj['y_pos'] = parseInt(position.y/0.463);
                        document.getElementById('x_pos_dim_' + dimension.id).value = newObj['x_pos'];
                        document.getElementById('y_pos_dim_' + dimension.id).value = newObj['y_pos'];
                        document.getElementById('width_dim_' + dimension.id).value = newObj['width'];
                        document.getElementById('height_dim_' + dimension.id).value = newObj['height'];
                        setComponentDimensions(newComponents);
                        setUnSavedChanges(true);
                      }}
                      >
                      <div className={"mediaThumb draggable" + ((activeSettingId === dimension.id)?(' focus'):(''))} id={"card_" + dimension.id}>
                        <div 
                          className="flip-card"
                          id={"cardInner_" + dimension.id}
                          >
                            <div className="flip-card-inner">
                              <div className="draggableImage flip-card-front">
                                
                                  {((dimension.file_format === 'img')||(dimension.file_format === 'statePNGButton')) ? (<img
                                      draggable="false"
                                      className="imgDefault"
                                      src={dimension.image_path}
                                      alt=""
                                  />) : null}
                                  {(dimension.file_format === 'svg') ? (<img
                                    draggable="false"
                                    className="imgDefault"
                                    src={dimension.svg_path}
                                    alt=""
                                />) : null}

                                  {(dimension.file_format === 'vid')?(<video 
                                    draggable="false" 
                                    className="vidDefault"
                                    width={dimension.width * 0.463}
                                    height={dimension.height * 0.463}
                                    playsInline
                                    >
                                      <source src={dimension.video_path} type="video/mp4" />
                                    </video>) : null }
                                  
                               
                                
                              </div>
                      
                            </div>
                          </div>
                      </div>
                    </Rnd>):null
                   
                  )}
                </div>
                
              </div>
              
            </div>
            
            {((showManifest)?(<div id="menuBtn" className="closeBtn" onClick={() => handleCloseSettings()}>
            <img src={Close} alt="close btn" />
    </div>):(<div id="menuBtn" onClick={() => handleMenuBtn()}>
        <svg id="burger" width="30" height="30" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path id="bar1" fill="none" stroke="#fff" strokeWidth="3" strokeLinejoin="bevel" strokeLinecap="round" d="m 5,14 54,0"/>
            <path id="bar2" fill="none" stroke="#fff" strokeWidth="3" strokeLinejoin="bevel" strokeLinecap="round" d="m 5,30 54,0"/>
            <path id="bar3" fill="none" stroke="#fff" strokeWidth="3" strokeLinejoin="bevel" strokeLinecap="round" d="m 5,46 44,0"/>
          </g>
        </svg>
      </div>))}
            

            <div id="manifestColumn" className={((activeSettingId)?("settingsOn"):("settingsOff") + ((menuOpen)?(' show'):(' slide')))}>

            <div className="settingsHeading">FILES</div>
            <div className="settingsList">
            
            {componentDimensions
              .sort((a, b) => a.component_ordering < b.component_ordering ? 1 : -1)
              // .filter(file => (file.is_popup === false))
              .map((dimension, i) => 
              (dimension) ?
               (<div
                onClick={(e) => handleManifestFileClick("card_" + dimension.id)}
                key={'settingFile_' + i}
                >
                  <div ref={nodeRef}>
                  <div id={"settings_" + dimension.id}>
                  <div className="mediaFile" onClick={() => showSettings(dimension.id, i, false)}>
                  {stripImg(dimension.name)}
                  {(activeSectionObj)?((activeSectionObj.background_ids.includes(dimension.id))?(" *"):("")):null}
                  </div>
              
                    <div className={"mediaSettings" + 
                      (((showManifest)&&(activeSettingId === dimension.id))?(
                          " showSettings on"
                        ):(
                          " off"
                          )
                      )
                    }>

                    <div className="mediaSettingsInner">
                    {(!dimension.has_state_images)?
                      (<div className="settingsGroup"> 
                      <div className="settingsRow">
                        <div className="canvasLabel">Component ID: {dimension.component_id}</div>
                        <div className="canvasLabel">File ID: {dimension.id}</div>
                      </div>
                        <div className="settingsRow">
                      
                          <h4>{stripImg(dimension.name)}</h4> 
                          <input type="file" id="fileReplace" accept={acceptValues} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, false)} multiple={false} />
                        </div>
                        {(dimension.file_format === "statePNGButton") ? 
                          (
                            <React.Fragment>
                              <span className="settingsRow">
                              <span className="canvasLabel">Selected Button</span>
                                <p>{stripImg(dimension.name_on)}</p>
                                <input type="file" id="fileOnReplace" accept={acceptValues} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, true)} multiple={false} />
                              </span>
                              <span className="settingsRow">
                              <span className="canvasLabel">Heading</span>
                                <input type="text" key={dimension.id + '_state_heading'} placeholder={dimension.component_state_heading} onChange={() => setUnSavedChanges(true)} id={'state_heading_' + dimension.id} />
                              </span>
                            </React.Fragment>
                          ) : ("")}
                      </div>):("")}
                      
                      {((section.states.length > 1)&&(dimension.file_format !== "statePNGButton"))?(
                        <div className="settingsGroup stateGroup">
                        {(dimension.has_state_images)?
                        (
                          <span>
                          <div className="settingsRow bottomRule">
                            <div className="canvasLabel">Component ID: {dimension.component_id}</div>
                            <div className="canvasLabel">File ID: {dimension.id}</div>
                          </div>

                          <div className="groupLabel"><p>State Images</p></div>
                          {section.states.map((state, k) => (
                          <span className="stateSettingsRow" id={"state_row_" + k} key={"state_" + k}>
                             <span>
                              <span className="canvasLabel">
                              {state.formats.filter((obj0) => obj0.display_id === aspectRatio).map((obj, l) => (
                                <span className="fileNameGroup" key={"state_format_" + l}>
                                  <span className="stateRow canvasLabel"></span>
                                  <span className="fileName">{stripImg(obj.file)} [{state.id}]</span>
                                </span>
                              ))}
                              </span>
                              {(k > 0) ? (
                                <span>
                  
                                {dimension.componentdimensionstateimages.map((obj1, m) => (
                                  (obj1.state_id === String(state.id)) ? (
                                    <span className="fileNameGroup" key={"state_format_image_" + m}>
                                      <span className="fileName">{stripImg(obj1.name)}</span>
                                    </span>
                                    ) : ("")
                                ))}
                             
                                <input type="file" accept={"image/png, image/jpeg, image/webp"} data-stateid={state.id} onChange={(e) => handleStateMediaAddReplace(dimension.id, e, state.id, state.state_heading)} multiple={false} />

                                </span>
                                ) : (
                                  <div className="fileNameGroup">
                                    <span className="fileName">{stripImg(dimension.name)}</span>
                                    <input type="file" id="fileReplace" accept={acceptValues} onChange={(e) => handleMediaReplace(dimension.background, dimension.file_format, dimension.id, e, false)} multiple={false} />
                                  </div>
                                )}
                            </span>
                            
                                
                          </span>

                          ))}
                          </span>
                          ):
                          (<div className="btn1" onClick={() => handleShowStateOptions(dimension.id)}>Add State Images +</div>)
                          }
                          </div>
                      ):("")}

                      <div className="settingsGroup"> 
                        <div className="groupLabel"><p>Layout</p></div>
                        <div className="settingsRow halfRow"><span className="canvasLabel">Width (px): </span> 
                          
                          <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_width'} placeholder={dimension.width} id={'width_dim_' + dimension.id} />

                        </div>
                        <div className="settingsRow halfRow rightRow">
                          <span className="canvasLabel">Height (px)</span>
                          
                          <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_height'} placeholder={dimension.height} id={'height_dim_' + dimension.id} />

                        </div>
                        <div className="settingsRow halfRow">
                          <span className="canvasLabel">Left (px)</span>

                          <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_x_pos'} placeholder={dimension.x_pos} id={'x_pos_dim_' + dimension.id} />

                        </div> 
                        <div className="settingsRow halfRow rightRow">
                          <span className="canvasLabel">Top (px)</span>

                          <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} key={dimension.id + '_y_pos'} placeholder={dimension.y_pos} id={'y_pos_dim_' + dimension.id} />

                        </div>
                        <div className="settingsRow halfRow">
                          <span className="canvasLabel">Z Index</span>

                          <input type="number" min="0" className="valueInput" onClick={(e) => handleInputFocus(e)} disabled={(dimension.background) ? ("disabled") : (false)}  key={dimension.id + '_ordering'} placeholder={dimension.component_ordering} id={'ordering_dim_' + dimension.id} />

                        </div>
                        <div className={"settingsRow halfRow rightRow"  + ((dimension.file_format === "statePNGButton") ? (" off"):(""))} data-bkg={dimension.background}>
                          <span className="canvasLabel">Bkg</span>
                          <div className={"switch" + ((dimension.background) ? " on" : " off")} onClick={() => handleBackgroundActive(dimension.id, i)}>
                              <div className="switchLabel">{((dimension.background) ? " On" : " Off")}</div>
                              <div className="dot"></div>
                          </div>
                        </div>
                        <div className="settingsRow halfRow">
                          <span className="canvasLabel">Active</span>
                          <div className={"switch" + ((dimension.active) ? " on" : " off")} onClick={() => handleOnActive(dimension.id, i)}>
                              <div className="switchLabel">{((dimension.active) ? " On" : " Off")}</div>
                              <div className="dot"></div>
                          </div>
                        </div>

                        <div className="settingsRow halfRow">
                          <div className={"lockIcon" + ((dimension.is_locked) ? " on" : " off")} onClick={() => handleAspectLock(dimension.id, i)}>
                              <img src={(dimension.is_locked)?(Lock):(Unlock)} alt="lock" />
                          </div>
                        </div>
                      </div>


                      <div className={"settingsGroup" + ((dimension.file_format === "statePNGButton") ? (" off"):(""))}> 
                      <div className="groupLabel"><p>REFERENCE</p></div>
                        <div className={"settingsRow" + ((dimension.popup)?(' disabled'):(""))}>
                          <span className="canvasLabel">Link</span>
                          <input type="text" key={dimension.id + '_link'} disabled={(dimension.popup)?('disabled'):(false)} placeholder={dimension.component_link} id={'link_' + dimension.id} />
                       
                        </div>

                        <div className={"settingsRow" + ((dimension.popup)?(' disabled'):(""))}>
                         
                          <span className="canvasLabel">Link Heading</span>
                          <input type="text" key={dimension.id + '_link_heading'} disabled={(dimension.popup)?('disabled'):(false)} placeholder={dimension.component_link_heading} id={'link_heading_' + dimension.id} />
                        </div>

                      <div className={"settingsRow existingFileRow" + ((dimension.component_link)?(' disabled'):(""))}>
                        {(dimension.popup)?(

                          <div className="existingFile">
                          <span className="canvasLabel">Pop Up</span>
                            <a href={dimension.popup_path} rel="noreferrer" target="_blank">{dimension.popup_name}</a> 

                            <div className="deleteX" onClick={() => handleDeletePopUp(dimension.popup_id, dimension.id)}><img src={CloseDelete} alt="close" /></div>
                          </div>

                        ):(
                          <div>
                        <div className="btn1" onClick={() => handleAddPopup(dimension.id, 'img')}>Add Pop Up +</div>
                        <input type="file" id="popUpload" accept={acceptValues} onChange={() => handlePopUpAdded('img')} multiple={false} />
                        </div>
                        )}
                        </div>
                      </div>


                      <div className="settingsGroup" id="animDropdown"> 
                          <div className="groupLabel"><p>ANIMATION</p> </div>
                          <ul className="dropDown">
                            <li>
                              <span className="dropDownBtn" onClick={() => handleShowAnimDropDown()}>

                                {(dimension.typeName) ? (<span className="dropDownValue">{dimension.typeName}</span>) : ("Select Animation Type") }
                                <svg xmlns="http://www.w3.org/2000/svg" id="downArrow" width="21" height="12.968" viewBox="0 0 21 12.968">
                                  <path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform={(showAnimDropDown)?('translate(0 0) rotate(0)'):('translate(21 12.968) rotate(180)')} fill="#fff"/>
                                </svg> 
                              
                                </span>

                              <ul className={"mediaDropDown " + ((showAnimDropDown) ? ('on') : ('off'))}>
                                <li className="animSelect"><span onClick={() => handleAnimChoice('parallax', dimension.id)}>Parallax</span> {(dimension.typeName === 'parallax') ? (<span className="clearValue" onDoubleClick={() => handleAnimChoice('none', dimension.id)}>&#215;</span>):(null)}</li>
                                <li className="animSelect"><span onClick={() => handleAnimChoice('page', dimension.id)}>Page</span> {(dimension.typeName === 'page') ? (<span className="clearValue" onClick={() => handleAnimChoice('none', dimension.id)}>&#215;</span>):(null)}</li>
                              </ul>
                            </li>
                          </ul>

                          {dimension.typeName && !unSavedChanges && <React.Fragment><div className="groupLabel"><p>PRESETS</p> </div>
                          <ul className="dropDown">
                            <li>
                              <span className="dropDownBtn" onClick={() => setShowAnimPresetDropDown(current => !current)}>

                                <span className="dropDownValue">Select Animation Type</span>
                                <svg xmlns="http://www.w3.org/2000/svg" id="downArrow" width="21" height="12.968" viewBox="0 0 21 12.968">
                                  <path d="M10.5,0,0,10.5l2.467,2.467L10.5,4.952l8.033,8.015L21,10.5Z" transform={(showAnimPresetDropDown)?('translate(0 0) rotate(0)'):('translate(21 12.968) rotate(180)')} fill="#fff"/>
                                </svg> 
                              
                                </span>

                              <ul className={"mediaDropDown " + ((showAnimPresetDropDown) ? ('on') : ('off'))}>
                               

                                {animationPresets && animationPresets.filter((obj) => obj.animation_type === dimension.typeName).map((item, i) => 
                                  <li className="animSelect" key={'animPreset_' + i} onClick={() => runAnimPreset(item, dimension.id, dimension.is_locked, dimension.display_name, dimension.partner_ids)}><span>{item.name}</span></li>
                               ) }

                              </ul>
                            </li>
                          </ul>
                          </React.Fragment>}

                          {
                            (dimension.typeName) ? (
                            <React.Fragment>
                              
                            {(dimension.animations.map((anim, i) => 
                              
                            <div className="animObj" key={"anim_" + dimension.id + i}>
                              <div className="settingsRow">
                                <div className="animLabel">{anim.property} Animation <span className="clearValue" onDoubleClick={(e) => handleDeleteAnim(anim.id)}>&#215;</span></div>
                              </div>
                              <div className="animRow">
                                <div className="settingsRow">
                                  {AnimationProperties.map((item, i) => 
                                    <div key={'property_' + i} className={"animBtn" + ((anim.property === item.slug) ? (' on') : (''))} onClick={(e) => handleAnimValue(dimension.id, anim.id, 'property', item.slug, e)}>{item.name}</div>
                                  )}
                                </div>
                                <div>                                
                                  <div className="settingsRow halfRow">
                                    <span className="canvasLabel">Start</span>
                                    <input type="text" placeholder={anim.start} id={"inputValue_start_" + anim.id} className="valueInput" autoComplete="off" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'start', e.target.value, e)} />
                                  </div>

                                  <div className="settingsRow halfRow rightRow">
                                    <span className="canvasLabel">End</span>
                                    <input type="text" placeholder={anim.end} id={"inputValue_end_" + anim.id} className="valueInput" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'end', e.target.value, e)} />
                                  </div>
                                  
                                  <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_start_" + anim.id + '_dd'}>
                                  
                                  {(anim.property === 'opacity') && (opacityChoices.map((item, i) => 
                                    <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                                  ))}

                                  {(anim.property === 'scale') && (scaleChoices.map((item, i) => 
                                    <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                                  ))}

                                  {(anim.property === 'translateX') && (xPosChoices.map((item, i) => 
                                    <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                                  ))}

                                  {(anim.property === 'translateY') && (yPosChoices.map((item, i) => 
                                    <li className="animSelect" key={"startSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_" + anim.id, "inputValue_start_" + anim.id + '_dd', dimension.id, anim.id, 'start')}>{item}</span></li>
                                  ))}

                                  </ul>

                                  <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_end_" + anim.id + '_dd'}>
                                  {(anim.property === 'opacity') &&(opacityChoices.map((item, i) => 
                                    <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                                  ))}
                                  {(anim.property === 'scale') &&(scaleChoices.map((item, i) => 
                                    <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                                  ))}
                                  {(anim.property === 'translateX') &&(xPosChoices.map((item, i) => 
                                    <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                                  ))}
                                  {(anim.property === 'translateY') &&(yPosChoices.map((item, i) => 
                                    <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_" + anim.id, "inputValue_end_" + anim.id + '_dd', dimension.id, anim.id, 'end')}>{item}</span></li>
                                  ))}
                                    
                                  </ul>

                                </div>
                                

                                {(dimension.typeName === 'page')? (<React.Fragment>

                                <div className="settingsRow halfRow">
                                  <span className="canvasLabel">Duration</span>
                                  <input type="number" placeholder={anim.duration} id={"inputValue_duration_" + anim.id} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'duration', e.target.value, e)} step="0.01" />
                                </div>

                                <div className="settingsRow halfRow rightRow">
                                  <span className="canvasLabel">Delay</span>
                                  <input type="number" placeholder={anim.delay} id={"inputValue_delay_" + anim.id} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'delay', e.target.value, e)} step="0.01" />
                                </div>

                                <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_duration_" + anim.id + '_dd'}>
                                  {(durationChoices.map((item, i) => 
                                    <li className="animSelect" key={"durationSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_duration_" + anim.id, "inputValue_duration_" + anim.id + '_dd', dimension.id, anim.id, "duration")}>{item}</span></li>
                                  ))}
                                  </ul>

                                <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_delay_" + anim.id + '_dd'}>
                                  {(delayChoices.map((item, i) => 
                                    <li className="animSelect" key={"delaySelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_delay_" + anim.id, "inputValue_delay_" + anim.id + '_dd', dimension.id, anim.id, "delay")}>{item}</span></li>
                                  ))}
                                  </ul>
                                
                                  

                                </React.Fragment>) : null }
                              
                              {(dimension.typeName === 'parallax')? (<React.Fragment><div className="settingsRow halfRow">
                                <span className="canvasLabel">Start Parallax</span>
                                <input type="number" id={"inputValue_startParallax_" + anim.id} placeholder={anim.startParallax} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'startParallax', e.target.value, e)} step="0.1" />
                                </div>

                              <div className="settingsRow halfRow rightRow">
                                <span className="canvasLabel">End Parallax</span>
                                <input type="number" id={"inputValue_endParallax_" + anim.id} placeholder={anim.endParallax} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleAnimValue(dimension.id, anim.id, 'endParallax', e.target.value, e)} step="0.1" />
                              </div>
                              
                              <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_startParallax_" + anim.id + '_dd'}>
                                  {(startParallaxChoices.map((item, i) => 
                                    <li className="animSelect" key={"startParallaxSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_startParallax_" + anim.id, "inputValue_startParallax_" + anim.id + '_dd', dimension.id, anim.id, "startParallax")}>{item}</span></li>
                                  ))}
                                  </ul>
                                
                                <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_endParallax_" + anim.id + '_dd'}>
                                {(endParallaxChoices.map((item, i) => 
                                  <li className="animSelect" key={"endParallaxSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_endParallax_" + anim.id, "inputValue_endParallax_" + anim.id + '_dd', dimension.id, anim.id, "endParallax")}>{item}</span></li>
                                ))}
                                </ul>
                              
                              </React.Fragment>) : null }
                              <div className="settingsRow">
                              <span className="canvasLabel">Ease</span>
                                {EasingProperties.map((item, i) => <div key={'easing_' + i} className={"animBtn" + ((anim.ease === item.slug) ? (' on') : (''))} onClick={(e) => handleAnimValue(dimension.id, anim.id, 'ease', item.slug, e)}>{item.name}</div> )}
                              </div>
                            </div>
                          </div>

                            ))}
                                
                            {(showNewAnimObj === false) ?
                            (<div className="settingsRow">
                              <div className="btn1" onClick={(e) => handleAddNewAnimation()}>
                                Add {toTitleCase(dimension.typeName)} Animation +
                              </div>
                            </div>) : (null)}


                            <div className={"animObj newAnimObj" + ((showNewAnimObj)?(' on'):(""))}>
                            
                            <div className="animRow">

                              <div className="settingsRow">
                              { AnimationProperties.map((item, i) => 
                                <div key={'property_' + i} className={"animBtn" + ((Object.keys(newAnimObj).length > 0)?((newAnimObj['property'] === item.slug) ? (' on') : ('')):(''))} onClick={(e) => handleNewAnimValue('property', item.slug, e)}>{item.name}</div>
                             ) }
                            </div>
                                

                              <div className="settingsRow halfRow">
                                <span className="canvasLabel">Start</span>
                                <input type="text" className="valueInput newValueInput" id={"inputValue_start_new"} onClick={(e) => handleInputFocus(e)} onChange={(e) => handleNewAnimValue('start', e.target.value, e)} defaultValue="" />
                                
                              </div>

                              <div className="settingsRow halfRow rightRow">
                                <span className="canvasLabel">End</span>
                                <input type="text" onClick={(e) => handleInputFocus(e)} id={"inputValue_end_new"} className="valueInput newValueInput" onChange={(e) => handleNewAnimValue('end', e.target.value, e)} defaultValue="" />
                              </div>

                              <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_start_new_dd"}>
                                  
                              {(animPropOn === 'opacity') && (opacityChoices.map((item, i) => 
                                <li className="animSelect" key={"startSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_new", "inputValue_start_new_dd", null, null, 'start')}>{item}</span></li>
                              ))}
                              {(animPropOn === "scale") && (scaleChoices.map((item, i) => 
                                <li className="animSelect" key={"startSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_new", "inputValue_start_new_dd", null, null, 'start')}>{item}</span></li>
                              ))}
                              
                              {(animPropOn === 'translateX') && (xPosChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_new", "inputValue_start_new_dd", null, null, 'start')}>{item}</span></li>
                              ))}
                            
                              {(animPropOn === 'translateY') && (yPosChoices.map((item, i) => 
                              <li className="animSelect" key={"startSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_start_new", "inputValue_start_new_dd", null, null, 'start')}>{item}</span></li>
                              ))}


                              </ul>

                              <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_end_new_dd"}>
                              {(animPropOn === 'opacity') && (opacityChoices.map((item, i) => 
                                <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_new", "inputValue_end_new_dd", null, null, "end")}>{item}</span></li>
                              ))}
                              {(animPropOn === "scale") && (scaleChoices.map((item, i) => 
                                <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_new", "inputValue_end_new_dd", null, null, "end")}>{item}</span></li>
                              ))}
                              {(animPropOn === 'translateX') && (xPosChoices.map((item, i) => 
                                <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_new", "inputValue_end_new_dd", null, null, "end")}>{item}</span></li>
                              ))}
                              {(animPropOn === 'translateY') && (yPosChoices.map((item, i) => 
                                <li className="animSelect" key={"endSelect_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_end_new", "inputValue_end_new_dd", null, null, "end")}>{item}</span></li>
                              ))}
                              </ul>

                              {(dimension.typeName === 'page')? (<React.Fragment>

                              <div className="settingsRow halfRow">
                                <span className="canvasLabel">Duration</span>
                                <input type="text" id={"inputValue_duration_new"} onClick={(e) => handleInputFocus(e)} className="valueInput newValueInput" onChange={(e) => handleNewAnimValue('duration', e.target.value, e)} min="0" step="0.01" />
                              </div>


                              <div className="settingsRow halfRow rightRow">
                                <span className="canvasLabel">Delay</span>
                                <input type="number" onClick={(e) => handleInputFocus(e)} id="inputValue_delay_new" className="valueInput newValueInput" onChange={(e) => handleNewAnimValue('delay', e.target.value, e)} min="0" step="0.01" />
                              </div>

                              <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_duration_new_dd"}>
                              {(durationChoices.map((item, i) => 
                                <li className="animSelect" key={"durationSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_duration_new", "inputValue_duration_new_dd", null, null, "duration")}>{item}</span></li>
                              ))}
                              </ul>

                            <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_delay_new_dd"}>
                              {(delayChoices.map((item, i) => 
                                <li className="animSelect" key={"delaySelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_delay_new", "inputValue_delay_new_dd", null, null, "delay")}>{item}</span></li>
                              ))}
                              </ul>

                            </React.Fragment>) : null }

                            
                            {(dimension.typeName === 'parallax')? (<React.Fragment><div className="settingsRow halfRow">
                              <span className="canvasLabel">Start Parallax</span>
                              <input type="number" id="inputValue_startParallax_new" className="valueInput newValueInput" onChange={(e) => handleNewAnimValue('startParallax', e.target.value)} min="0" onClick={(e) => handleInputFocus(e)} step="0.1" />
                              </div>

                            <div className="settingsRow halfRow rightRow">
                              <span className="canvasLabel">End Parallax</span>
                              <input type="number" id="inputValue_endParallax_new" className="valueInput newValueInput" onChange={(e) => handleNewAnimValue('endParallax', e.target.value)} min="0" onClick={(e) => handleInputFocus(e)} step="0.1" />
                            </div></React.Fragment>) : null }


                            <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_startParallax_new_dd"}>
                            {(startParallaxChoices.map((item, i) => 
                              <li className="animSelect" key={"startParallaxSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_startParallax_new", "inputValue_startParallax_new_dd", null, null, "startParallax")}>{item}</span></li>
                            ))}
                            </ul>

                            <ul className={"dropDown mediaDropDown inputDropDown off"} id={"inputValue_endParallax_new_dd"}>
                            {(endParallaxChoices.map((item, i) => 
                              <li className="animSelect" key={"endParallaxSelect_new_"+ i}><span onClick={(e) => handleDropDownValue(e, item, "inputValue_endParallax_new", "inputValue_endParallax_new_dd", null, null, "endParallax")}>{item}</span></li>
                            ))}
                            </ul>
                            
                            <div className="settingsRow">
                                <span className="canvasLabel">Ease</span>

                                {EasingProperties.map((item, i) => <div key={'easing_' + i} className={"animBtn" + ((Object.keys(newAnimObj).length > 0)?((newAnimObj['ease'] === item.slug) ? (' on') : ('')):(''))} onClick={(e) => handleNewAnimValue('ease', item.slug)}>{item.name}</div> )}
                                

                            </div>
                          </div>

                        </div>


                        
                        
                        
                        </React.Fragment>

                          ) : ( null )
                          
                          }
                      </div>

                      {
                        (dimension.has_state_images) ? (
                  
                        <div className="settingsGroup"> 
                        <div className="groupLabel"><p>STATE ANIMATION</p> </div>

                        {(dimension.state_animations.map((anim, i) => 

                        <div className="animObj" key={"anim_" + dimension.id + i}>
                          <div className="settingsRow">
                            <div className="animLabel">{anim.property} Animation <span className="clearValue" onDoubleClick={(e) => handleDeleteAnim(anim.id)}>&#215;</span></div>
                          </div>
                          <div className="animRow">
                            <div className="settingsRow">
                            { AnimationProperties.map((item, i) => 
                              <div key={'property_' + i} className={"animBtn" + ((anim.property === item.slug) ? (' on') : (''))} onClick={(e) => handleStateAnimValue(dimension.id, anim.id, 'property', item.slug)}>{item.name}</div>
                           ) }
                            </div>
                            
                            
                            <div className="settingsRow halfRow">
                              <span className="canvasLabel">Start</span>
                              <input type="text" placeholder={anim.start} className="valueInput" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleStateAnimValue(dimension.id, anim.id, 'start', e.target.value)} />
                            </div>

                            <div className="settingsRow halfRow rightRow">
                              <span className="canvasLabel">End</span>
                              <input type="text" placeholder={anim.end} className="valueInput" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleStateAnimValue(dimension.id, anim.id, 'end', e.target.value)} />
                            </div>

            

                            <div className="settingsRow halfRow">
                              <span className="canvasLabel">Duration</span>
                              <input type="number" placeholder={anim.duration} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleStateAnimValue(dimension.id, anim.id, 'duration', e.target.value)} step="0.01" />
                            </div>

                            <div className="settingsRow halfRow rightRow">
                              <span className="canvasLabel">Delay</span>
                              <input type="number" placeholder={anim.delay} className="valueInput" min="0" onClick={(e) => handleInputFocus(e)} onChange={(e) => handleStateAnimValue(dimension.id, anim.id, 'delay', e.target.value)} step="0.01" />
                            </div>

                          <div className="settingsRow">
                          <span className="canvasLabel">Ease</span>
                            {EasingProperties.map((item, i) => <div key={'easing_' + i} className={"animBtn" + ((anim.ease === item.slug) ? (' on') : (''))} onClick={(e) => handleStateAnimValue(dimension.id, anim.id, 'ease', item.slug)}>{item.name}</div> )}
                          </div>
                        </div>
                      </div>
                      ))}
                            
                      {(showNewStateAnimObj === false) ?

                        (<div className="settingsRow">
                          <div className="btn1" onClick={(e) => handleAddNewStateAnimation()}>
                            Add State Animation +
                          </div>
                        </div>) : (null)}


                        <div className={"animObj newAnimObj" + ((showNewStateAnimObj)?(' on'):(""))}>
                        
                        <div className="animRow">

                          <div className="settingsRow">
                          { AnimationProperties.map((item, i) => 
                            <div key={'property_' + i} className={"animBtn" + ((Object.keys(newStateAnimObj).length > 0)?((newStateAnimObj['property'] === item.slug) ? (' on') : ('')):(''))} onClick={(e) => handleNewStateAnimValue('property', item.slug)}>{item.name}</div>
                         ) }
                        </div>
                            

                          <div className="settingsRow halfRow">
                            <span className="canvasLabel">Start</span>
                            <input type="text" className="valueInput newValueInput" onChange={(e) => handleNewStateAnimValue('start', e.target.value)} defaultValue="" />
                          </div>

                          <div className="settingsRow halfRow rightRow">
                            <span className="canvasLabel">End</span>
                            <input type="text" className="valueInput newValueInput" onChange={(e) => handleNewStateAnimValue('end', e.target.value)} defaultValue="" />
                          </div>

                    

                          <div className="settingsRow halfRow">
                            <span className="canvasLabel">Duration</span>
                            <input type="text" className="valueInput newValueInput" onChange={(e) => handleNewStateAnimValue('duration', e.target.value)} min="0" step="0.01" />
                          </div>


                          <div className="settingsRow halfRow rightRow">
                            <span className="canvasLabel">Delay</span>
                            <input type="number" id="newAnimInput4" className="valueInput newValueInput" onChange={(e) => handleNewStateAnimValue('delay', e.target.value)} min="0" step="0.01" />
                          </div>

                        
                        <div className="settingsRow">
                            <span className="canvasLabel">Ease</span>
                            {EasingProperties.map((item, i) => <div key={'easing_' + i} className={"animBtn" + ((Object.keys(newStateAnimObj).length > 0)?((newStateAnimObj['ease'] === item.slug) ? (' on') : ('')):(''))} onClick={(e) => handleNewStateAnimValue('ease', item.slug)}>{item.name}</div> )}
                        </div>
                      </div>
                     
                      
                    </div>
                    
                    </div>

                  
                   

                      ) : ( null )
                      
                      }

                    </div>
                     
                     
                      
                      <div className="settingsRow" id="saveRow">
                          <div className={"closeFormBtn btn" + ((unSavedChanges) ? (" unsaved") : (""))} onClick={() => showSettings(dimension.id, i, true)}>Save</div>
                          <div className="deleteBtn btn" onDoubleClick={() => handleDeleteMedia(dimension.id)}>Delete</div>
                      </div>
                      
                      </div>   
                  </div>
                  </div>
               </div>
               ):null
             )}
             
             </div>     
             
             

             </div>
            
            <div className="fieldRow" id="canvasRow">
            {(sections.length > 0) ?
                (<Swiper
                    className="canvasSwiper"
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    onSwiper={(swiper) => handleSwiperInit(swiper)}
                >
                {sections
                  .sort((a, b) => a.ordering > b.ordering ? 1 : -1)
                  .map((section, i) => 
                  <SwiperSlide 
                    key={'swiper_' + i}
                    onClick={(e) => handleSwiperClick(section.id)}
                    className={"canvasFrame aspectRatio_" + (aspectRatio) + ((section.id === sectionId) ? " on" : " off")}
                    style={{
                      'width': containsObject(aspectRatio, section.widths)
                    }}
                  > 
                    <div>
                    {(section.title)?(<div className="cueLine"><div className="cueLineTitle">{section.title}</div></div>):null}
                    <div className="deleteSectionX" onDoubleClick={() => handleConfirmDeleteSection(sectionId)}><img src={CloseDelete} alt="closedelete" /></div>
                    <div className="canvasThumbBorder">
                    <img className="backgroundThumb" src={getSectionBackgroundImage(aspectRatio, section.widths)} alt="" />
                    </div>
                    </div>
                    
                  </SwiperSlide>
                )}
            
                </Swiper>) : null}

               <div id="addSection" onClick={(e) => handleAddSection(e)} >
                  
                    Add Page +
                  
                    </div>
            </div>
        </div>
      </section>
    );
}

export default Home;
// <div className="details">ID: {dimension.id}</div>