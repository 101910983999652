import { Suspense } from "react";
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom'
import Canvas from './component/app.canvas';
import Narratives from './component/app.narratives';
import Loader from "./static/svg/loader.svg";
import LeftArrow from "./static/svg/leftarrow.svg";
import './static/css/app.css';
// import logo from './logo.svg';

const routes = [
  // { path: '', name: 'BPS 3.0 Admin', Component: Narratives },
  { path: '/narratives/:id', name: '', Component: Canvas },
]

function App() {
  return (
    <Router>
      <div className="app">
        <header className="app-header">
          <nav>
            
            {routes.filter(route => (route.name)).map((route, i) => (
              <NavLink
                key={route.path}
                className={(i === 0)? "menuLink first" : "menuLink"}
                to={route.path}
                // onClick={() => handlePageChange(route.path)}
                activeClassName="on"
              >
                {route.name}
              </NavLink>
            ))}
          </nav>
        </header>


        {routes.map(({ path, Component }) => (
          <Switch key={path}>
          <Route exact  path={path}>
            {({ match }) => (
              <div className={"component" + ((match !== null)?(" on"):(" off"))}>
                <Suspense fallback={<div id="loading"><img src={Loader} alt="" /></div>}>
                  <Component active={(match !== null)?(true):(false)} />
                  </Suspense>
              </div>
            )}
          </Route>
          </Switch>
        ))}


      </div>
    
      </Router>
    );

}

export default App;

